import Loader from "@/javascript/components/loader"
import { slideTransition } from "@/javascript/components/animations/slide"
import { fadeOut } from "@/javascript/components/animations/fade"
import { closest } from "@/javascript/components/tools/closest"
import CSRFFetch from "@/javascript/components/tools/fetch"
import reportError from "@/javascript/components/errors"

let openModal = null

const preventDefault = (e) => {
  e = e || window.event
  if(e.preventDefault) {
    e.preventDefault()
  }
  e.returnValue = false
}

export default class Modal {
  static defaultOptions = {
    // Title to be displayed at the top of the modal
    title: undefined,
    // URL to fetch content of the modal
    url: undefined,
    // Error message to display when content loading encounters the error
    errorMessage: "This page couldn't be loaded. Please close the modal and try again.",
    // Class to add to modal, for extra styling.
    class: undefined,
    // CSS selector which will select and set an HTML element from the
    // loaded HTML as the Title of the modal.
    titleSelector: 'header.modal-title',
    // HTTP method to use to open the modal
    method: 'get'
  }

  static overlay () {
    if(this._overlay) {
      return this._overlay
    }

    this._overlay = document.createElement('div')
    this._overlay.classList.add('modal-overlay')
    document.body.appendChild(this._overlay)
    return this._overlay
  }

  constructor (opts) {
    this.options = {}
    Object.entries(this.constructor.defaultOptions).forEach((entry) => {
      const [key, defaultValue] = entry
      this.options[key] = opts[key] || defaultValue
    })

    this.parent = document.body
    this.overlay = this.constructor.overlay()
    this.overlay.modal = this
    this.events = {}
  }

  bindToElem (button) {
    button.addEventListener('click', (e) => {
      preventDefault(e)
      if(openModal) {
        openModal.close()
      }
      this.open()
    })
  }

  on (event, handler) {
    this.events[event] ||= []
    this.events[event].push(handler)
  }

  trigger (event, ...args) {
    Promise.all(
      (this.events[event] || []).map((handler) => {
        return new Promise((resolve) => {
          handler(...args)
          resolve()
        })
      })
    )
  }

  open() {
    this._buildWrapper()
      .then(() => {
        this.parent.classList.add('with-modal')
        this.overlay.classList.add('open')
        this.wrapper.style.setProperty('visibility', 'hidden')
        this.wrapper.style.removeProperty('visibility')
        openModal = this
        return this.container
      })
      .then(() => this.trigger('open', this))
      .then(() => {
        if(this.options.url) {
          slideTransition(this.container, 300, this._loadContent())
        }
      })
  }

  close() {
    if(document.querySelector('.modal-overlay .js-refresh-page-on-modal-close') != null) {
      window.location.reload()
    }

    this.parent.classList.remove('with-modal')
    // Selectize controls live outside of the modal, at the bottom of the document, so we have
    // to manually destroy any selectize controls in the modal as they won't be garbage collected
    this.container.querySelectorAll('select.selectized').forEach((select) => {
      select.selectize.destroy()
    })
    this.container.innerHTML = ''
    this.overlay.classList.remove('open')
    this.overlay.innerHTML = ''
    this.trigger('close')
    openModal = null
  }

  _buildWrapper() {
    return new Promise((resolve) => {
      this.wrapper = document.createElement('div')
      this.wrapper.classList.add('modal')
      this.wrapper.classList.add('loading')
      this.header = document.createElement('header')
      if(this.options.title) {
        const title = document.createElement('h3')
        title.innerHTML = this.options.title
        this.header.appendChild(title)
      }
      if(this.options.class) {
        this.wrapper.classList.add(this.options.class)
      }
      this.wrapper.appendChild(this.header)
      this.containerWrapper = document.createElement('div')
      this.containerWrapper.classList.add('container-wrapper')
      this.container = document.createElement('article')
      this.containerWrapper.appendChild(this.container)
      this.wrapper.appendChild(this.containerWrapper)

      const closeButton = document.createElement('div')
      closeButton.classList.add('modal-close')
      closeButton.addEventListener('click', (e) => {
        preventDefault(e)
        this.close()
      })
      this.wrapper.appendChild(closeButton)
      this.overlay.appendChild(this.wrapper)
      resolve(this.wrapper)
    })
  }

  _loadContent() {
    const loader = document.createElement('div')
    loader.classList.add('loader')
    this.containerWrapper.appendChild(loader)

    return CSRFFetch(this.options.url, {method: this.options.method})
      .then((response) => {
        if(!response.ok) {
          throw Error(response.statusText)
        }
        return response.text()
      })
      .then((html) => {
        const json = this.resolveJson(html)
        if(typeof(json) == 'object' && json.redirect) {
          return window.location = json.redirect
        }
        return html
      })
      .then((html) => this.setContent(html))
      .then((container) => new Loader(this.container).all())
      .then((container) => this.trigger('load', container))
      .then(() => this.removeLoading())
      .catch((e) => {
        reportError(e)
        this.container.innerHTML = `<span class='modal-load-error'>${this.options.errorMessage}</span>`
        this.removeLoading()
      })
  }

  resolveJson (data) {
    try {
      return JSON.parse(data)
    } catch {
      return data
    }
  }

  setContent (html) {
    this.container.innerHTML = html
    this.setModalTitle(this.container)
    return this.container
  }

  setModalTitle (_html) {
    if(!this.options.titleSelector) {
      return
    }

    const newHeader = this.container.querySelector(this.options.titleSelector)
    if(!newHeader) {
      return
    }

    const currentHeader = this.wrapper.querySelector('header')
    while(currentHeader.firstChild) {
      currentHeader.firstChild.remove()
    }
    while(newHeader.firstChild) {
      currentHeader.appendChild(newHeader.firstChild)
    }
    newHeader.remove()
  }

  removeLoading() {
    const loader = this.containerWrapper.querySelector('.loader')
    if (loader) {
      fadeOut(loader, 200)
    }
    this.wrapper.classList.remove('loading')
  }

  isScrollable() {
    return this.containerWrapper.clientHeight < this.container.clientHeight
  }
}

// TODO: Figure out how JS garbage collector collect our DOM elements!
// See: https://stackoverflow.com/questions/45466055/js-garbage-collector-going-crazy
let elements = []


export function initModalLink (element) {
  if(element.modal) {
    return element.modal
  }
  const options = {
    url: element.dataset.url || element.href,
    title: element.dataset.title,
    class: element.dataset.class,
    titleSelector: element.dataset.titleselector,
    method: element.hasAttribute('method') && element.attributes['method'].value
  }
  elements.push(element)
  element.modal = new Modal(options)
  element.modal.bindToElem(element)
  return element.modal
}

Loader.functional((content, resolve) => {
  content.querySelectorAll('[data-modal]').forEach((elem) => {
    initModalLink(elem)
  })
  resolve()
})

document.addEventListener('formReloaded', (e) => {
  const target = e.target
  const modal = closest(target, '.modal')
  if(!modal) {
    return
  }

  let targetScroll = target.offsetTop - 30
  if(targetScroll < 0) {
    targetScroll = 0
  }
  openModal.container.scrollTop = targetScroll
})

document.addEventListener('formSubmitted', (e) => {
  if(!e.detail.success) {
    return
  }

  const target = e.target
  const modal = closest(target, '.modal')
  if(!modal) {
    return
  }
  openModal.setModalTitle(e.detail.response)
})

document.addEventListener('click', (e) => {
  const event = e || window.event
  const target = e.target
  if(!closest(target, '.modal-close')) {
    return
  }

  const modal = closest(target, '.modal-overlay')
  if(!modal) {
    return
  }

  e.preventDefault()
  if(openModal) {
    openModal.close()
  }
})
